<template>
  <v-card v-bind="$attrs" outlined color="grey lighten-4">
    <v-card-title :class="`${type}--text`">
      <slot name="title">
        Danger
      </slot>
    </v-card-title>

    <v-card-subtitle v-if="$slots.subtitle">
      <slot name="subtitle" />
    </v-card-subtitle>

    <v-card-text class="black--text">
      <slot />
    </v-card-text>

    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-state-card',
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (value) => {
        // The value must match one of these strings
        return !!['success', 'warning', 'error', 'info'].includes(value)
      }
    }
  }
}
</script>
